import React, { Component } from 'react';
import PropTypes            from 'prop-types';

export const contextName = 'FeatureFlagContext';
export const FeatureFlagContext = React.createContext();
export default class FeatureFlagProvider extends Component {
  state = {
    flags: window.featureFlags,
  }

  isEnabled = (feature) => {
    const { flags } = this.state;

    if (feature in flags) return flags[feature]
    console.warn(`Feature Flag not found ${feature}`)
    return feature in flags && flags[feature];
  }

  render() {
    return (
      <FeatureFlagContext.Provider
        value={{
          isEnabled: this.isEnabled,
        }}
      >
        {this.props.children}
      </FeatureFlagContext.Provider>
    )
  }
}


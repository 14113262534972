import "./Image.scss";
import React from "react";

export default class Image extends React.PureComponent {
  classes = () => {
    let list = [
      "image"
    ]
    if (this.props.loading) {
      list.push("is-loading")
    }
    if (this.props.ratio) {
      list.push("has-ratio")
      list.push(`is-${this.props.ratio}`)
    }
    return list.join(" ")
  }
  render() {
    return(
      <div className={`Image ${this.props.className || ""}`}>
        <figure
          className={this.classes()}
          style={{backgroundImage: `url(${this.props.src}`}}
        />
      </div>
    )
  }
}

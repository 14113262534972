import "./TerminalLayout/TerminalLayout.scss";

import React from "react";
import { Link, Redirect } from "react-router-dom";
import request from "request"
import usesFeatures from "usesFeatures"
import Loader from "Loader"

import Terminal from "lib/auth/Terminal"
import Navigation from "./TerminalLayout/Navigation"
import FlashMessages from "FlashMessages"

class TerminalLayout extends React.PureComponent {
  constructor (props) {
    super(props)
    const whoAmI = Terminal.getWhoAmI() || {}

    this.state = {
      terminal: whoAmI.Terminal,
    }
  }

  componentDidMount() {
    if (!this.state.terminal) this.props.fetchData("auth/terminal/whoami", this.cacheWhoAmI)
  }

  cacheWhoAmI = (data) => {
    Terminal.setWhoAmI(data)
    this.setState(data)
  }

  handleLogout = () => {
    Terminal.clearAuthToken()
    Terminal.clearWhoAmI()
    this.setState({logOut: true})
  }

  render() {
    if (this.state.logOut) return <Redirect to="/login" />
    if (!this.props.dataFetched || !this.state.terminal) return false

    const { terminal } = this.state
    return(
      <div className="TerminalLayout">
        <FlashMessages />
        <Navigation terminal={terminal} handleLogout={this.handleLogout} />
        <div className="view-wrapper is-webapp">
          <div className="page-content-wrapper">
            <div className="page-content is-relative">
              { terminal ? this.props.children : <Loader /> }
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default usesFeatures(['data'], TerminalLayout)

const KEYS = {
  authToken: "terminalAuthToken",
  lastPage: "terminalLastPage",
  whoAmI: "terminalWhoAmI",
  currentOrderId: "terminalCurrentOrderId",
  currentLayout: "terminalCurrentLayout",
}

const Terminal = {
  isAuthenticated: () => Terminal.getAuthToken() !== null,

  get: (key) => {
    let value = localStorage.getItem(KEYS[key])
    if(value === undefined) return null
    return value
  },

  setAuthToken: (token) => localStorage.setItem(KEYS.authToken, token),
  getAuthToken: () => Terminal.get("authToken"),
  clearAuthToken: () => localStorage.removeItem(KEYS.authToken),
  isAuthenticated: () => Terminal.getAuthToken() !== null,

  setCurrentOrderId: (data) => localStorage.setItem(KEYS.currentOrderId, data),
  getCurrentOrderId: () => localStorage.getItem(KEYS.currentOrderId),
  clearCurrentOrderId: () => localStorage.removeItem(KEYS.currentOrderId),

  setWhoAmI: (data) => localStorage.setItem(KEYS.whoAmI, JSON.stringify(data)),
  getWhoAmI: () => JSON.parse(localStorage.getItem(KEYS.whoAmI)),
  clearWhoAmI: () => localStorage.removeItem(KEYS.whoAmI),

  getLayout: () => localStorage.getItem(KEYS.currentLayout),
  setLayout: (layout) => localStorage.setItem(KEYS.currentLayout, layout),
}

export default Terminal


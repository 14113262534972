import "./Modal.scss";

import React from "react";
import { Link } from "react-router-dom";
import request from "request"
import usesFeatures from "usesFeatures"

export default class Modal extends React.PureComponent {
  render() {
    const isOpen = this.props.isOpen || this.props.open
    return(
      <div className={`Modal ${this.props.size}`}>
        <div className={`modal h-modal ${isOpen ? "is-active" : ""}`}>
          <div onClick={this.props.toggleModal} className="modal-background"></div>
          <div className="modal-content">
            <div className="modal-card">
              { this.props.title &&
                <header className="modal-card-head">
                  <div className="modal-card-title">{ this.props.title } </div>
                </header>
              }
              <div className="modal-card-body">
                { this.props.children }
              </div>
              { this.props.actions &&
                <div className="modal-card-foot is-start">
                  { this.props.actions }
                </div>
              }
            </div>
          </div>
          { this.props.toggleModal &&
            <button onClick={this.props.toggleModal} className="modal-close is-large" aria-label="close"></button>
          }
        </div>
      </div>
    )
  }
}

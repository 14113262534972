const KEYS = {
  authToken: "catchAuthToken",
  lastPage: "catchLastPage",
}

const User = {
  getAuthToken: () => {
    let token = localStorage.getItem(KEYS.authToken)
    if(token === undefined) return null
    return token
  },

  setAuthToken: (token) => localStorage.setItem(KEYS.authToken, token),
  clearAuthToken: () => localStorage.removeItem(KEYS.authToken),
  isAuthenticated: () => User.getAuthToken() !== null,

  setLastPage: (url) => localStorage.setItem(KEYS.lastPage, url),
  getLastPage: () => localStorage.getItem(KEYS.lastPage),
  clearLastPage: () => localStorage.removeItem(KEYS.lastPage),
}

export default User

import "./Checkbox.scss";

import React from "react";

export default class Checkbox extends React.PureComponent {
  handleOnChange = ({target}) => {
    this.props.onChange(target.checked)
  }

  render() {
    return(
      <div className="Checkbox field block">
        <label className="checkbox is-solid">
          <input
            onChange={this.handleOnChange}
            type="checkbox"
            checked={this.props.value}
          />
          <span />
          { this.props.label }
        </label>
      </div>
    )
  }
}

import "./Icon.scss";

import React from "react";

// NOTE: Using Font Awesome, search icons here
// https://fontawesome.com/v5.0/icons?d=gallery&p=1

export default class Icon extends React.PureComponent {
  style = () => {
    let types = {
      solid: "fas",
      brand: "fab",
    }
    let type = types[this.props.style]
    return type || "far"
  }
  render() {
    return(
      <i
        onClick={this.props.onClick}
        className={`Icon icon ${this.style()} fa-${this.props.icon} ${this.props.className}`}
      />
    )
  }
}

Icon.defaultProps = {
  style: "solid",
  className: "",
}

import "./InternalLayout.scss";

import React from "react";
import { Link } from "react-router-dom";
import { ApplicationContext } from 'ApplicationContext'

import User from "User";
import usesFeatures from 'usesFeatures'

import Loader from "Loader"
import Navigation from "./Navigation"
import FlashMessages from "FlashMessages"

class InternalLayout extends React.PureComponent {
  static contextType = ApplicationContext;

  componentDidMount() {
    const { user, venue } = this.context.CurrentUserContext
    if(!user) this.context.CurrentUserContext.updateWhoAmI()
  }

  crossLogLink = () => {
    let host = window.location.host.replace("internal.", "app.")
    let token = User.getAuthToken()
    return [ `${window.location.protocol}/`, host, `cross-login?token=${token}`].join("/")
  }

  render() {
    const { user, venue } = this.context.CurrentUserContext

    return(
      <div className={`InternalLayout ${this.props.className}`}>
        <FlashMessages />
        <Navigation crossLogLink={this.crossLogLink()} />
        <div className="view-wrapper">
          <div className="page-content-wrapper">
            <div className="page-content is-relative">
              { user && this.props.children }
              { !user && (<Loader />)}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default usesFeatures(["data"], InternalLayout)

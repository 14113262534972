import React from "react";
import { Link } from "react-router-dom";

import Icon from "Icon"

export default class Navigation extends React.Component {
  renderNavItem = (text, link, icon) => {
    const path = window.location.pathname
    const isActive = path == link || (link != "/" && path.includes(link))
    return(
      <li className={`${isActive ? "is-active" : "" }`}>
        <Link to={link} className={`${isActive ? "is-active" : "" }`}>
          <Icon icon={icon} className="sidebar-svg" />
          { text }
        </Link>
      </li>
    )
  }


  render() {
    return(
      <div className="main-sidebar is-bordered">
          <div className="sidebar-brand is-bordered">
              <a href="/">
                <img className="light-image" src="/images/stupid-logo.svg" alt=""/>
              </a>
          </div>
          <div className="sidebar-inner">
              <ul className="icon-menu">
                { this.renderNavItem("Dashboard", "/", "home") }
                <li className="has-children">
                  <span>Accounts & Users</span>
                  <ul>
                    { this.renderNavItem("Accounts", "/accounts", "university") }
                    { this.renderNavItem("Vendors", "/vendors", "truck-moving") }
                    { this.renderNavItem("Venues", "/venues", "building") }
                    { this.renderNavItem("Users", "/users", "user") }
                    { this.renderNavItem("Permissions", "/permissions", "user-lock") }
                  </ul>
                </li>
                <li className="has-children">
                  <span>Customers (Fans)</span>
                  <ul>
                    { this.renderNavItem("Orders", "/orders", "shopping-cart") }
                  </ul>
                </li>
                <li className="has-children">
                  <span>Events</span>
                  <ul>
                    { this.renderNavItem("Seasons", "/seasons", "calendar-alt") }
                    { this.renderNavItem("Events", "/events", "futbol") }
                  </ul>
                </li>
                <li className="has-children">
                  <span>Finance</span>
                  <ul>
                    { this.renderNavItem("Payouts", "/payouts", "hand-holding-usd") }
                    { this.renderNavItem("Invoices", "/invoices", "file-invoice-dollar") }
                  </ul>
                </li>
                <li className="has-children">
                  <span>Sales</span>
                  <ul>
                    { this.renderNavItem("Sales Tools", "/sales", "comment-dollar") }
                    { this.renderNavItem("Reports", "/reports", "chart-line") }
                  </ul>
                </li>
                <li className="has-children">
                  <span>Engineering</span>
                  <ul>
                    { this.renderNavItem("Printers", "/printers", "print") }
                    { this.renderNavItem("Card Readers", "/readers", "credit-card") }
                    { this.renderNavItem("Engineering", "/eng", "wrench") }
                    { this.renderNavItem("Deliverect", "/deliverect", "warehouse") }
                  </ul>
                </li>
                <li>
                  <a href={this.props.crossLogLink} className="button is-link mr-2">App Login</a>
                </li>
              </ul>
          </div>
      </div>
    )
  }
}

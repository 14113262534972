import React from "react";
import "./AuthLayout.scss";

import FlashMessages from "FlashMessages"

export default class AuthLayout extends React.Component {
  render() {
    return(
      <div className="AuthLayout auth-wrapper">
        <FlashMessages />
        <div className="auth-wrapper-inner columns is-gapless is-vcentered">
          <div className="login column is-4 ">
            <section className="section">
              <div className="">
                <div className="block has-text-centered brand">
                  <img src="/images/stupid-logo.svg" />
                </div>
                { this.props.children }
              </div>
            </section>
          </div>
          <div className="interactive-bg column is-8" />
        </div>
      </div>
    )
  }
}

import React from "react";
import "./Input.scss";

export default class Input extends React.PureComponent {
  handleOnChange = ({target}) => this.props.onChange(target.value)

  fieldClasses = () => {
    let classes = ["field", "block", "has-input"]
    if (this.props.append) classes.push("has-addons")
    if (this.props.horizontal) classes.push("is-horizontal")
    return(classes.join(" "))
  }

  controlClasses = () => {
    let classes = ["control", "has-validation"]
    if (this.props.errors[this.props.errorKey]) classes.push("has-error")
    return(classes.join(" "))
  }

  render() {
    return(
      <div className="Input field">
        { this.props.label && !this.props.horizontal && <label className="label block">{ this.props.label }</label>}
        <div className={this.fieldClasses()}>
          { this.props.label && this.props.horizontal && <label className="label block">{ this.props.label }</label>}
          <div className={this.controlClasses()}>
            <input
              className="input"
              name={this.props.name ? this.props.name : null}
              onChange={this.handleOnChange}
              type={this.props.type ? this.props.type : "text" }
              step={this.props.type == "number" ? "0.01" : null }
              placeholder={this.props.placeholder}
              value={ this.props.value || ""}
              disabled={this.props.disabled || false}
            />
          </div>
          { this.props.append && (
            <p className="control">
              <a className="button is-static">
                { this.props.append }
              </a>
            </p>
          )}
        </div>
        { this.props.hint &&
          <p className="is-6">{ this.props.hint }</p>
        }
        { this.props.errors[this.props.errorKey] &&
          <p className="help danger-text">{ this.props.errors[this.props.errorKey] }</p>
        }
      </div>
    )
  }
}

Input.defaultProps = {
  errors: {}
}

import "./VenueSelector.scss";

import React from "react";
import request from "request"
import User from "User"

export default class VenueSelector extends React.Component {
  constructor (props) {
    super(props)
    this.state = {}
  }

  canUseComponent = () => {
    return this.props.user.type == "admin" || this.props.user.type == "internal"
  }

  fetchVenues = () => {
    if (this.state.venues) return this.setState({ open: !this.state.open })

    let req = request.get("venue_admin/venues")
    req.then(({data}) => {
      this.setState({venues: data.venues, open: true})
    })
  }

  toggleList = () => {
    this.setState({ open: !this.state.open })
  }

  changeVenue = (venue_id) => {
    let req = request.post("venue_admin/user/change_venue", { venue_id: venue_id })
    req.then(({data}) => {
      if (data.success) {
        window.location.assign("/")
      }
    })
  }

  renderVenueLink = (venue) => {
    if (this.props.user.venue_id == venue.id) return null
    return(
      <a onClick={this.changeVenue.bind(null, venue.id)} key={venue.id} className="navbar-item">{venue.name}</a>
    )
  }

  render() {
    if (!this.canUseComponent()) return null

    const { venues } = this.state
    return(
      <div className={`navbar-item has-dropdown ${this.state.open ? "is-active" : ""}`}>
        <a className="navbar-link" onClick={this.fetchVenues}>Change Venue</a>
        { venues && this.state.open && (
          <div className="navbar-dropdown is-right">
            { venues.map((venue) => {
              return(this.renderVenueLink(venue))
            })}
            <a className="navbar-item" onClick={this.toggleList}>Close</a>
          </div>
        )}
      </div>
    )
  }
}

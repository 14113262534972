import React from "react";
import { Link, Redirect } from "react-router-dom";
import "./VenueAdminLayout.scss";
import { ApplicationContext } from "ApplicationContext"
import usesFeatures from 'usesFeatures'

import requests from "request";

import User from "User";
import Icon from "Icon"
import VenueSelector from "VenueSelector";
import LanguageSelector from "LanguageSelector"

class Navigation extends React.Component {
  static contextType = ApplicationContext
  constructor (props) {
    super(props)
    this.state = {}
  }

  renderNavItem = (text, link, icon) => {
    const path = window.location.pathname
    const isActive = path == link || (link != "/" && path.includes(link))
    return(
      <li className={`${isActive ? "is-active" : "" }`}>
        <Link to={link} className={`${isActive ? "is-active" : "" }`}>
          <Icon icon={icon} className="sidebar-svg" />
          { text }
        </Link>
      </li>
    )
  }

  render() {
    if (this.state.logOut) return <Redirect to="/login" />
    const { user, venue, t } = this.props
    const { canUse } = this.context.CurrentUserContext
    return(
      <div className="main-sidebar is-bordered pt-4">
        <div className="sidebar-inner">
          <ul className="icon-menu">
            { this.renderNavItem(t("simple.stores"), "/stores", "store") }
            { this.renderNavItem(t("simple.categories"), "/categories", "tags") }
            { this.renderNavItem(t("simple.products"), "/products", "box-open") }
            { this.renderNavItem(t("simple.reports"), "/reports", "chart-line") }
            { this.renderNavItem(t("simple.terminals"), "/terminals", "cash-register") }
            { initialState.account?.isCustomerDataEnabled &&
              this.renderNavItem(t("simple.customerData"), "/customers", "users")
            }
          </ul>
        </div>
      </div>
    )
  }
}

export default usesFeatures(["translation"], Navigation)

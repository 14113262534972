import "./StoreLayout.scss";

import React from "react";
import { Link, Redirect } from "react-router-dom";
import request from "request"
import usesFeatures from "usesFeatures"
import { SkewLoader } from "react-spinners"

import Store from "Store"
import Navigation from "./Navigation"
import FlashMessages from "FlashMessages"

class StoreLayout extends React.PureComponent {
  constructor (props) {
    super(props)
    const whoAmI = Store.getWhoAmI() || {}

    this.state = {
      store: whoAmI.store,
    }
  }

  componentDidMount() {
    if (!this.state.store) this.props.fetchData("store/whoami", this.cacheWhoAmI)
  }

  cacheWhoAmI = (data) => {
    Store.setWhoAmI(data)
    this.setState(data)
  }

  handleLogout = () => {
    Store.clearAuthToken()
    Store.clearWhoAmI()
    this.setState({logOut: true})
  }

  render() {
    if (this.state.logOut) return <Redirect to="/login" />
    if (!this.props.dataFetched && !this.state.store) return false

    const { store } = this.state
    return(
      <div className="StoreLayout">
        <FlashMessages />
        <Navigation store={store} handleLogout={this.handleLogout} />
        <div className="view-wrapper">
          <div className="page-content-wrapper">
            <div className="page-content is-relative">
              { !store && <Spinner /> }
              { store && this.props.children }
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default usesFeatures(['data'], StoreLayout)

class Spinner extends React.PureComponent {
  render() {
    return(
      <div className="Loader">
        <SkewLoader size={40} color="#d91e18" />
      </div>
    )
  }
}

import { compose } from "redux"

import hasData from "./features/hasData"
import hasInputs from "./features/hasInputs"
import hasSound from "./features/hasSound"
import hasPayments from "./features/hasPayments"
import hasPagination from "./features/hasPagination"
import hasWebsockets from "./features/hasWebsockets"
import { withRouter } from "react-router";
import { withTranslation } from 'react-i18next';

const FEATURES = {
  data: hasData,
  form: hasInputs,
  sound: hasSound,
  history: withRouter,
  payments: hasPayments,
  pagination: hasPagination,
  websockets: hasWebsockets,
  translation: (c) => withTranslation()(c),
}

export default function usesFeatures (features, WrappedComponent) {
  const selected = features.map((f) => { return(FEATURES[f]) })
  return compose(...selected)(WrappedComponent)
}

import "./Button.scss";

import React from "react";

export default class Button extends React.PureComponent {
  classes = () => {
    const classes = [
      "button",
      this.props.className ? this.props.className.split(" ") : [],
    ].flat()

    if (this.props.loading) {
      classes.push("is-loading")
      classes.push("is-disabled")
    }

    if (this.props.disabled) {
      classes.push("is-disabled")
    }

    classes.push(this.buttonColor())

    return classes.join(" ")
  }

  buttonColor = () => {
    switch (this.props.color) {
      case "red":
        return "is-danger"
      case "yellow":
        return "is-warning"
      case "green":
        return "is-success"
      case "link":
        return "is-link"
      default:
        return "is-primary"
    }
  }

  onClick = (e) => {
    e.preventDefault()
    if (this.props.onClick) {
      this.props.onClick()
    }
  }

  render() {
    return(
      <span className={this.classes()} onClick={this.onClick}>
        { this.props.text }
      </span>
    )
  }
}

import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import wrapComponent from "ApplicationWrapper"
import Store from "Store"
import { MobileView } from "react-device-detect";

import ProtectedRoute from "ProtectedRoute"
import RotateDevice from "RotateDevice"

import LoginView from "Store/LoginView";

import DashboardView from "Store/DashboardView";
import OrderList from "Store/OrderList"
import OrderView from "Store/OrderView"
import OrderCompleteForm from "Store/OrderCompleteForm"

import ProductList from "Store/ProductList"

import SectionList from "Store/SectionList"

import FourOhFour from "FourOhFour"

export default props => (
  <Router>
    <MobileView>
      <RotateDevice />
    </MobileView>
    <Switch>
      <Route path="/login" exact component={wrapComponent(LoginView, "Auth")} />

      <ProtectedRoute path="/" exact component={wrapComponent(DashboardView, "Store")} auth={Store} />
      <ProtectedRoute path="/orders" exact component={wrapComponent(OrderList, "Store")} auth={Store} />
      <ProtectedRoute path="/orders/:orderId" exact component={wrapComponent(OrderView, "Store")} auth={Store} />
      <ProtectedRoute path="/orders/:orderId/complete" exact component={wrapComponent(OrderCompleteForm, "Store")} auth={Store} />

      <ProtectedRoute path="/products" exact component={wrapComponent(ProductList, "Store")} auth={Store} />

      <ProtectedRoute path="/sections" exact component={wrapComponent(SectionList, "Store")} auth={Store} />
      <ProtectedRoute path="/sections/:sectionId/:sectionName" exact component={wrapComponent(SectionList, "Store")} auth={Store} />

      <Route path="/*" component={wrapComponent(FourOhFour)} />
    </Switch>
  </Router>
);

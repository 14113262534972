const KEYS = {
  authToken: "storeAuthToken",
  lastPage: "storeLastPage",
  whoAmI: "storeWhoAmI",
}

const Store = {
  isAuthenticated: () => Store.getAuthToken() !== null,

  get: (key) => {
    let value = localStorage.getItem(KEYS[key])
    if(value === undefined) return null
    return value
  },

  setAuthToken: (token) => localStorage.setItem(KEYS.authToken, token),
  getAuthToken: () => Store.get("authToken"),
  clearAuthToken: () => localStorage.removeItem(KEYS.authToken),
  isAuthenticated: () => Store.getAuthToken() !== null,

  setWhoAmI: (data) => localStorage.setItem(KEYS.whoAmI, JSON.stringify(data)),
  getWhoAmI: () => JSON.parse(localStorage.getItem(KEYS.whoAmI)),
  clearWhoAmI: () => localStorage.removeItem(KEYS.whoAmI),
}

export default Store


import "./RotateDevice.scss";

import React from "react";
import { useMobileOrientation } from 'react-device-detect';

export default function RotateDevice() {
  const { isLandscape } = useMobileOrientation()

  if (isLandscape) {
    return null
  }

  return(
    <div className="RotateDevice">
      <div className="Content">
        Landscape mode is recommended for the Store Platform.
        <br/>
        Please Rotate your Device

        <img src="/images/icons/rotate.png" className="mt-4"/>
      </div>
    </div>
  )
}

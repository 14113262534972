import React from "react";

import useSound from 'use-sound';
import soundFile from 'assets/notify.mp3';

export default function hasSound(Component) {
  return function WrappedComponent(props) {
    const [ playSound ] = useSound(soundFile)
    return <Component {...props} playSound={playSound} />;
  }
}


import "./Header.scss";

import React from "react";
import { Link } from "react-router-dom";
import request from "request"

export default class Header extends React.Component {
  render() {
    return(
      <div className="Header block">
        <div className="page-title has-text-centered">
          { this.props.backLink && (
            <Link className="button is-ghost" to={this.props.backLink}>
              <span className="menu-toggle has-chevron">
                <span className="icon-box-toggle active">
                  <span className="rotate">
                  <i className="icon-line-bottom"></i>
                  <i className="icon-line-top"></i>
                  <i className="icon-line-center"></i>
                  </span>
                </span>
              </span>
            </Link>
          )}

          <div className="title-wrap">
            <h1 className="title is-4">{ this.props.text }</h1>
            { this.props.subtitle &&
              <p>{ this.props.subtitle }</p>
            }
          </div>
          <div className="toolbar ml-auto no-print">
            { this.props.children }
          </div>
        </div>
      </div>
    )
  }
}

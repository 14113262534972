import "./OrderView.scss";

import React from "react";
import { Link } from "react-router-dom";
import request from "request"
import usesFeatures from "usesFeatures"
import { capitalize } from "lodash"

import Image from "Image"
import Button from "Button"
import Header from "Header"
import Confirm from "Confirm"

const CANCEL_MESSAGE = "Are you sure you want to cancel this order? The customer will be completely refunded"

class OrderView extends React.PureComponent {
  constructor (props) {
    super(props)
    this.state = {}
  }

  componentDidMount() {
    const orderId = this.props.computedMatch.params.orderId
    this.props.fetchData(`store/orders/${orderId}`)
  }

  renderProduct = (product, i) => {
    const { order } = this.props

    return(
      <div key={product.id} className="columns is-mobile is-vcentered">
        <div className="column">
          <p className="is-size-5 has-text-weight-semibold">
            { product.name }
            { product.quantity > 1 ? ` x${product.quantity}` : "" }
          </p>
            { product.fulfilled != product.quantity && (order.status == "complete" || order.status == "cancelled") &&
              <p>
                Refunded { product.quantity - product.fulfilled }
              </p>
            }
          { product.modifiers.length > 0 && <p>
            <span className="mr-2 has-text-weight-bold">Extras:</span>
            { product.modifiers.map((m) => m.name).join(", ") }
          </p> }
          { !order.allInclusive && <p>${ product.price }</p> }
        </div>
      </div>
    )
  }

  handleReady = () => {
    if (this.state.readyLoading) return false

    this.setState({readyLoading: true})
    let req = request.post(`store/orders/${this.props.order.id}/ready`)
    req.then(({data}) => {
    this.setState({readyLoading: false})
      if (data.success) location.reload();
    })
  }

  handleAccepted = () => {
    if (this.state.acceptedLoading) return false

    this.setState({acceptedLoading: true})
    let req = request.post(`store/orders/${this.props.order.id}/accepted`)
    req.then(({data}) => {
      this.setState({acceptedLoading: false})
      if (data.success) location.reload();
    })
  }

  handleCancel = () => {
    if (this.state.cancelLoading) return false

    this.setState({cancelLoading: true})
    let req = request.post(`store/orders/${this.props.order.id}/cancel`)
    req.then(({data}) => {
      this.setState({cancelLoading: false})
      if (data.success) location.reload();
    })
  }

  handlePrintOrder = () => {
    if (this.state.printing) return false

    this.setState({printing: true})
    let req = request.post(`store/orders/${this.props.order.id}/print`)
    req.then(({data}) => {
      this.setState({printing: false})
      this.props.updateWith({order: data.order})
    })
  }

  canPrintTicket = () => {
    const { order } = this.props
    return(
      (order.allInclusive && order.status == "placed") ||
      (!order.allInclusive && order.status != "complete" && order.hasDelivery)
    )
  }

  render() {
    if (!this.props.dataFetched) return false

    const { order, cart, customer, canPrint } = this.props

    return(
      <div className="OrderView">
        <Header
          text={`Order #${order.number} for ${customer.name}`}
          backLink="/orders"
          linkText="Back to Orders"
          actions={true}
        >
          { order.status == "placed" &&
            <Confirm action={this.handleCancel} message={CANCEL_MESSAGE} className="CancelModal">
              <Button
                color="red"
                text="Cancel Order"
                className="mr-6"
                loading={this.state.cancelLoading}
              />
            </Confirm>
          }
          { this.canPrintTicket() && canPrint &&
            <Button
              color="yellow"
              loading={this.state.printing}
              onClick={this.handlePrintOrder}
              text={ order.printed ? "Print Ticket Again" : "Accept & Print Ticket" }
            />
          }
          { order.status == "placed" && !order.hasDelivery && !order.allInclusive &&
            <>
              { canPrint &&
                <Button
                  color="yellow"
                  loading={this.state.printing}
                  onClick={this.handlePrintOrder}
                  text={ order.printed ? "Print Ticket Again" : "Print Ticket" }
                />
              }
              <Button onClick={this.handleReady} loading={this.state.readyLoading} text="Ready for Pickup" />
            </>
          }
          { order.status == "placed" && (order.allInclusive || order.hasDelivery) &&
            <Button onClick={this.handleAccepted} loading={this.state.acceptedLoading} text="Accept Order" />
          }
          { (order.status == "ready" || order.status == "accepted") &&
            <Link to={`/orders/${order.id}/complete`} className="button is-primary">Complete Order</Link>
          }
        </Header>
        <div className="columns">
          <div className="column is-5">
            <div className="s-card">
              <p className="is-size-4 block mb-2">
                Confirmation/Pickup Code: <br/>
                <span className="has-text-weight-bold">{ [ order.confirmationCode.slice(0, 3), order.confirmationCode.slice(3) ].join('-') }</span>
              </p>

              { order.hasDelivery &&
                <p className="is-size-4 block mb-2">
                  Delivering to: <br/>
                  <span className="has-text-weight-bold">
                    { order.sectionName }{ order.location ? ` - ${order.location }` : "" }
                  </span>
                </p>
              }

              <h3 className="title">Customer Info</h3>
              <p className="block">
                <span className="has-text-weight-bold mr-2">Customer Name</span>
                { customer.name }
              </p>
              <p className="block">
                <span className="has-text-weight-bold mr-2">Phone Number</span>
                { customer.phone }
              </p>
            </div>
          </div>
          <div className="column">
            <div className="s-card">
              <h3 className="title">Order Info</h3>
              <p className="subtitle">
                Order Status:
                <span className={`Tag tag ${order.status}`}>{capitalize(order.status)}</span>
                { order.printed && canPrint && <span className="ml-2">Sent to Printer</span> }
              </p>
              { cart.products.map(this.renderProduct) }
              { cart.products.length == 0 &&
                <p className="title has-text-centered">Nothing here</p>
              }

              { !order.allInclusive &&
                <div className="">
                  <p>
                    <span className="has-text-weight-bold">Items Subtotal</span>
                    <span className="ml-2">${ order.subtotal }</span>
                  </p>
                  <p>
                    <span className="has-text-weight-bold">Sales Tax</span>
                    <span className="ml-2">${ order.taxes }</span>
                  </p>
                  { order.tip > 0 &&
                    <p>
                      <span className="has-text-weight-bold">Tip</span>
                      <span className="ml-2">${ order.tip.toFixed(2) }</span>
                    </p>
                  }
                  { order.discountAmount != "$0.00" &&
                    <p className="has-text-success-dark">
                      <span className="has-text-weight-bold">{order.discount.name}</span>
                      <span className="ml-2">{ order.discountAmount }</span>
                    </p>
                  }
                  <p>
                    <span className="has-text-weight-bold">Total</span>
                    <span className="ml-2">${ order.total }</span>
                  </p>
                </div>
              }
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default usesFeatures(['data'], OrderView)

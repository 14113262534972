import "./Confirm.scss";

import React from "react";
import Icon from "Icon"

export default class Confirm extends React.PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      open: false
    }
  }

  toggleModal = () => {
    if (!this.props.loading) {
      this.setState({open: !this.state.open})
    }
  }

  handleConfirm = () => {
    this.setState({open: false})
    this.props.action()
  }

  render() {
    return(
      <div className={`Confirm ${this.props.className}`}>
        <div className={`modal h-modal ${this.state.open ? "is-active" : ""}`}>
          <div onClick={this.toggleModal} className="modal-background"></div>
          <div className="modal-content">
            <div className="modal-card">
              <div className="modal-card-head">
                <h3>Are you sure?</h3>
                <span onClick={this.toggleModal} className="h-modal-close ml-auto">
                  <Icon icon="times" />
                </span>
              </div>
              <div className="modal-card-body">
                { this.props.message }
              </div>
              <div className="modal-card-foot is-start">
                <a onClick={this.toggleModal} className="button h-button h-modal-close">Cancel</a>
                <a onClick={this.handleConfirm} className="button h-button is-primary is-raised">Confirm</a>
              </div>
            </div>
          </div>
          <button onClick={this.toggleModal} className="modal-close is-large" aria-label="close"></button>
        </div>
        <div onClick={this.toggleModal}>
          { this.props.children }
        </div>
      </div>
    )
  }
}

import "./DashboardView.scss";

import React from "react";
import { Link, Redirect } from "react-router-dom";
import request from "request"
import usesFeatures from "usesFeatures"

import Form from "Form"
import Input from "Input"
import Header from "Header"
import Confirm from "Confirm"

class DashboardView extends React.Component {
  constructor (props) {
    super(props)
    this.state = {}
  }

  componentDidMount() {
    this.props.fetchData("/store/")
  }

  formatValue = (value) => {
    let val = value.replace(/\W/g, "")
    if (value.length > 7) return false
    if (val.length > 3) {
      val = [ val.slice(0, 3), val.slice(3) ].join("-")
    }
    this.props.updateField("pickup", val.toUpperCase())
  }

  handleOrderLookup = () => {
    this.setState({orderNotFound: false})
    const { pickup } = this.props.formData
    let req = request.get(`store/orders/search?code=${pickup}`)
    req.then(({data}) => {
      if (data.success) {
        this.setState({redirect: data.orderId})
      } else {
        this.setState({orderNotFound: true, code: pickup})
      }
    })
  }

  toggleStore = () => {
    let req = request.post("store/toggle", { open: !this.props.store.open })
    req.then(({data}) => this.props.updateWith(data))
  }

  render() {
    if (!this.props.dataFetched) return false
    if (this.state.redirect) return <Redirect to={`/orders/${this.state.redirect}`} />

    const { store } = this.props
    return(
      <div className="DashboardView">
        <Header text="Dashboard" />
        <div className="columns">
          <div className="column is-half is-mobile">
            <Form
              onSubmit={this.handleOrderLookup}
              title="Order Lookup"
            >
              <Input label="Pickup Code" type="search" onChange={this.formatValue} value={this.props.formData.pickup} />
              { this.state.orderNotFound &&
                <p className="message is-warning p-2">
                  Order not found for pickup code
                  <span className="has-text-weight-bold ml-1">{ this.state.code }</span>
                </p>
              }
              <span className="button is-primary" onClick={this.handleOrderLookup}>Lookup Order</span>
            </Form>
          </div>
          <div className="column is-half is-mobile">
            <div className="s-card">
              <h3 className="title is-size-4">Store Status - { store.open ? "Open" : "Closed" } </h3>
                <Confirm
                  action={this.toggleStore}
                  message="Are you sure you want to chagne the stores status? Any in process orders will still need to be completed or canceled"
                >
                { !store.open &&
                  <span className="button h-button is-success">Open Store</span>
                }
                { store.open &&
                  <span className="button h-button is-danger">Close Store</span>
                }
              </Confirm>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default usesFeatures(['data', 'form'], DashboardView)

import "./OrderList.scss";

import React from "react";
import { Link } from "react-router-dom";

import request from "request"
import usesFeatures from "usesFeatures"
import { capitalize } from "lodash"
import moment from "moment"

import Icon from "Icon"
import Table from "Table"
import Header from "Header"

class OrderList extends React.Component {
  constructor (props) {
    super(props)
    this.state = {}
  }

  componentDidMount() {
    this.props.fetchData("/store/orders", this.setOrders)
  }

  setOrders = ({store, orders}) => {
    this.props.subscribeTo("OrdersChannel", { storeId: store.id }, this.handleReceived.bind(this))
    this.setState({orders})
  }

  handleReceived({order}) {
    let orders = [ ...this.state.orders]
    orders.unshift(order)
    this.setState({orders})
    this.props.playSound()
  }

  goToOrder = (order) => {
    this.props.history.push(`/orders/${order.id}`)
  }

  emptyTable = () => {
    return(
      <h3 className="is-size-6 is-italic">
        No Orders
      </h3>
    )
  }

  tableHeaders = () => {
    const { store } = this.props
    const headers = {
      number: "Number",
      method: "Method",
      customerName: "Customer",
      status: "Status",
      printed: <Icon icon="print" className="large"/>,
      info: "Info",
      itemCount: "Items",
      total: "Total",
      placedAt: "Placed",
    }
    if (store.hasDelivery) {
      delete headers.confirmationCode
    } else {
      delete headers.sectionName
    }
    if (!store.hasPrinters) {
      delete headers.printed
    }

    return headers
  }

  render() {
    if (!this.props.dataFetched) return false

    return(
      <div className="OrderList">
        <Header text="Orders" />
        <Table
          empty={this.emptyTable()}
          showHeader={true}
          headers={this.tableHeaders()}
          formatters={ORDER_FORMATTERS}
          rows={this.state.orders}
          onCellClick={this.goToOrder}
        />
      </div>
    )
  }
}

const renderPrinted = (o) => {
  return(
    <span
      className={`hint--top tag ${o.printed ? "is-success" : "is-danger is-outlined"}`}
      data-hint={`${o.printed ? "Sent" : "Not Sent"} to printer`}
    >
      <Icon icon={o.printed ? "check" : "times"} />
    </span>
  )
}

const ORDER_FORMATTERS = {
  confirmationCode: (o) => [ o.confirmationCode.slice(0, 3), o.confirmationCode.slice(3) ].join("-"),
  method: (o) => o.sectionId ? "Delivery" : "Pickup",
  status: (o) => <span className={`Tag tag ${o.status}`}>{capitalize(o.status)}</span>,
  total: (o) => `$${o.total}`,
  placedAt: (o) => moment(o.placedAt).fromNow(),
  info: (o) => o.sectionId ? <p>{o.sectionName}<br/>{o.location}</p> : o.confirmationCode,
  printed: (o) => renderPrinted(o)
}

export default usesFeatures(["data", "history", "websockets", "sound"], OrderList)

import React from 'react'
import Appsignal from "@appsignal/javascript";
import { ErrorBoundary } from "@appsignal/react";

const appsignal = new Appsignal({
  key: window.appConfig.apk,
  revision: window.appConfig.v,
});

const FallbackComponent = () => (
  <div className="has-text-centered py-10">
    <div className="is-inline-block mx-auto mx-auto my-5 has-background-white p-4">
      Sorry, it looks like an unhandled error occured. <br/>
      You can go <a href="/">Back Home</a> or reload the page. <br/>
      If this persists, please reach out to sales@gocatch.io
    </div>
  </div>
);

export default class ErrorReporting extends React.Component {
  render() {
    return(
      <ErrorBoundary
        instance={appsignal}
        fallback={(error) => <FallbackComponent />}
      >
        { this.props.children }
      </ErrorBoundary>
    )
  }
}

import React from "react";
import request from "lib/request";

export default function hasPagination (WrappedComponent) {
  return class extends React.Component {
    constructor(props) {
      super(props)
      this.state = {
        pageData: props.pageData,
        pagy: props.pagy,
        page: 1,
      }
    }

    setPageData = ({pagy, pageData}) => this.setState({pagy, pageData})

    getPage = (url, page) => {
      let joinChar = url.indexOf("?") > 0 ? "&" : "?"
      const pageUrl = [ url, `page=${page}` ].join(joinChar)
      let req = request.get(pageUrl)
      req.then(({data}) => this.setState({pagy: data.pagy, pageData: data.pageData}))
    }

    render() {
      const { dataFetched } = this.state
      const pagy = this.state.pagy || this.props.pagy
      const pageData = this.state.pageData || this.props.pageData

      return (
        <WrappedComponent
          {...this.props}
          pagy={pagy}
          pageData={pageData}
          getPage={this.getPage}
          setPageData={this.setPageData}
        />
      )
    }
  }
}

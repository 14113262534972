import React, { useContext } from 'react';

import * as CurrentUserContext from "CurrentUserContext"
import * as FeatureFlagContext from "FeatureFlagContext"

export const ApplicationContext = React.createContext();

const CONTEXTS = [
  CurrentUserContext,
  FeatureFlagContext,
];

export default function ApplicationProviderWrapper(props) {
  let providerTree = (
    <ApplicationProvider contexts={props.contexts}>
      {props.children}
    </ApplicationProvider>
  );

  props.contexts.forEach(context => {
    providerTree = (
      <context.default {...props.values[context.contextName]}>
        {providerTree}
      </context.default>
    );
  })

  return providerTree;
}

ApplicationProviderWrapper.defaultProps = {
  contexts: CONTEXTS,
  values: {},
}

function ApplicationProvider(props) {
  let values = {};
  props.contexts.forEach(mainContext => {
    const { contextName } = mainContext;
    const contextValues = useContext(mainContext[contextName]);

    values = {
      ...values,
      [contextName]: {
        ...contextValues,
      }
    };
  });

  return (
    <ApplicationContext.Provider value={values}>
      {props.children}
    </ApplicationContext.Provider>
  );
}

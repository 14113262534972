import React from "react";
import "./NoLayout.scss";

export default class NoLayout extends React.Component {
  render() {
    return(
      <>
        { this.props.children }
      </>
    )
  }
}

import React from "react";
import { Link, Redirect } from "react-router-dom";
import "./VenueAdminLayout.scss";
import { ApplicationContext } from "ApplicationContext"
import usesFeatures from 'usesFeatures'

import requests from "request";

import User from "User";
import Icon from "Icon"
import VenueSelector from "VenueSelector";
import LanguageSelector from "LanguageSelector"

class TopNav extends React.Component {
  static contextType = ApplicationContext
  constructor (props) {
    super(props)
    this.state = {}
  }

  handleLogout = () => {
    let req = requests.delete("logout")
    req.then(({data}) => {
      if (data.success ) {
        User.clearAuthToken()
        this.setState({logOut: true})
      }
    })
  }

  toggleMenu = () => {
    this.setState({open: !this.state.open})
  }

  crossLogLink = () => {
    let host = window.location.host.replace("app.", "internal.")
    let token = User.getAuthToken()
    return [ `${window.location.protocol}/`, host, `cross-login?token=${token}`].join("/")
  }

  render() {
    if (this.state.logOut) return <Redirect to="/login" />
    const { user, venue, t } = this.props
    const { canUse } = this.context.CurrentUserContext
    return(
      <div className="webapp-navbar no-print">
        <div className="webapp-navbar-inner">
          <div className="left">
            <a href="/" className="brand">
              <img className="light-image" src="/images/stupid-logo.svg" alt="" />
            </a>
            <div className="separator"></div>
            <h1 id="webapp-page-title" className="title is-5">Venue Admin</h1>
          </div>
          <div className="center">
          </div>
          <div className="right">
            { user && (
              <>
                <span className="navbar-item">
                  { t("greeting") } {
                    <Link className="ml-1" to="/profile">{user.name}</Link>
                  }
                </span>
                { venue &&
                  <span className="navbar-item">
                    <Link to={`/venue/current`}>{venue.name}</Link>
                  </span>
                }
                <LanguageSelector />
                { user && canUse("change_venue") &&
                  <VenueSelector user={this.props.user} />
                }
              </>
            )}

            <div className="navbar-item">
              <div className="buttons">
                <a className="button is-outlined is-danger navbar-item" onClick={this.handleLogout} >Logout</a>
                { user && canUse("internal") &&
                  <a href={this.crossLogLink()} className="button is-link">Internal Login</a>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default usesFeatures(["translation"], TopNav)


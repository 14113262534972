import React from "react";
import { Redirect } from 'react-router-dom'

export default class ProtectedRoute extends React.Component {

  render() {
    const Component = this.props.component;
    const isAuthenticated = this.props.auth.isAuthenticated()

    if (!isAuthenticated) return <Redirect to={{ pathname: '/login' }} />

    return <Component { ...this.props } />
  }
}

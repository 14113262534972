import "./Table.scss";

import React from "react";
import { Link } from "react-router-dom";
import request from "request"
import usesFeatures from "usesFeatures"

export default class Table extends React.Component {
  handleOnClick = (row, e) => {
    const cellKey = e.target.getAttribute("data-key")
    if (this.props.skipClick.includes(cellKey) || !this.props.onCellClick) return false

    this.props.onCellClick(row)
  }

  handleRowClick = (row, e) => {
    if (!this.props.onRowClick) return false

    this.props.onRowClick(row)
  }

  renderHeader = () => {
    return(
      <thead>
        <tr className="has-text-left">
          { Object.keys(this.props.headers).map(key => {
            return(<th key={key}>{this.props.headers[key]}</th>)
          })}
        </tr>
      </thead>
    )
  }

  emptyTable = () => {
    const { headers } = this.props
    return(
      <tr>
        <td className="is-italic has-text-centered" colSpan={Object.keys(headers).length}>
          { this.props.empty || "No Data" }
        </td>
      </tr>
    )
  }

  renderCells = (row) => {
    const { headers, formatters, tdClasses } = this.props;
    return Object.keys(headers).map((key, i) => {
      return(
        <td key={key} data-key={key} onClick={this.handleOnClick.bind(null, row)} className={tdClasses && tdClasses[key] ? tdClasses[key] : ""}>
          { (formatters[key] ? formatters[key](row) : row[key]) || "-" }
        </td>
      )
    })
  }

  renderBody = () => {
    const { formatters, rows, headers } = this.props
    if (!rows || rows.length == 0) return this.emptyTable()
    return rows.map((r, i) => {
      return(
        <tr key={i} onClick={this.handleRowClick.bind(null, r)}>
          { this.renderCells(r) }
        </tr>
      )
    })
  }

  render() {
    const { showHeader } = this.props
    return(
      <div className="Table block">
        { (this.props.newRecordPath || this.props.actions) &&
          <div className="datatable-toolbar">
            { this.props.actions &&
              <div className="actions field">
                { this.props.actions }
              </div>
            }
            <div className="buttons">
              { this.props.newRecordPath && !this.props.hideNewRecord &&
                <Link to={this.props.newRecordPath} className="button h-button is-primary is-raised">
                  <span className="icon">
                    <i aria-hidden="true" className="fas fa-plus"></i>
                  </span>
                    <span>{ this.props.newRecordText } </span>
                </Link>
              }
            </div>
          </div>
        }
        <div className="table-wrapper">
          <table className={`table is-fullwidth is-datatable ${this.props.className}`}>
            { showHeader !== false && this.renderHeader() }
            <tbody>
              { this.props.isLoading ? (
                <tr className="loading">
                  <td colSpan={Object.keys(this.props.headers).length}>
                    &nbsp;
                  </td>
                </tr>
              ) : (
                this.renderBody()
              )}
            </tbody>
          </table>
        </div>
      </div>
    )
  }
}

Table.defaultProps = {
  skipClick: [],
  hideNewRecord: false,
}

import React from "react";
import "./Loader.scss";

import { BounceLoader } from "react-spinners"

export default class Loader extends React.PureComponent {
  render() {
    return(
      <div className="Loader">
        <BounceLoader size={80} color="#DDD" className="Bounce" />
      </div>
    )
  }
}

import React from "react";
import { Link } from "react-router-dom";
import Flash from "flash"
import request from "request"
import { startCase } from "lodash"

import Icon from "Icon"
import Button from "Button"
import Confirm from "Confirm"
import Modal from "Modal"

export default class Navigation extends React.Component {
  state = {
    readerStatus: null,
    checkingReader: false,
    isIdentifyModalOpen: false,
    identificationString: "",
    isIdentifying: false,
  }

  componentDidMount() {
    this.fetchReaderStatus()
  }

  renderNavItem = (text, link, icon) => {
    const isActive = window.location.pathname.includes(link)
    return(
      <Link to={link} className={`centered-link ${isActive ? "is-active" : "" }`}>
        <Icon icon={icon} className="sidebar-svg" />
        <span>{ text }</span>
      </Link>
    )
  }

  identifyReader = () => {
    const { terminal } = this.props
    const identificationString = Math.random().toString(36).substring(2, 8)
    this.setState({identificationString, isIdentifying: true})
    const req = request.post(`/terminals/${terminal.id}/reader/identify`, { identificationString })
    req.then(() => {
      this.setState({isIdentifyModalOpen: true})
    })
    req.catch(({response}) => {
      switch(response.data.error) {
        case "terminal_reader_offline":
          Flash.broadcast("error", "The reader is currently offline")
          break;
        case "terminal_reader_timeout":
          Flash.broadcast("error", "There was a timeout while identifying the reader. Please try again")
          break;
        default:
          Flash.broadcast("error", "There was an unknown error communicating with the reader")
      }
      this.setState({identificationString: null, isIdentifyModalOpen: false})
    })
    req.finally(() => {
      this.setState({isIdentifying: false})
    })
  }

  fetchReaderStatus = () => {
    this.setState({checkingReader: true})
    const req = request.get(`/terminals/${this.props.terminal.id}/reader/status`)
    req.then(({data}) => {
      const { readerStatus } = data
      this.setState({readerStatus, checkingReader: false})
    })
  }

  render() {
    const { terminal } = this.props
    const { readerStatus, checkingReader } = this.state

    return(
      <div className="webapp-navbar no-print">
        <Modal
          open={this.state.isIdentifyModalOpen}
        >
          <h3 className="title">Identifying Card Reader</h3>
          <h3 className="subtitle">Card Reader Displaying</h3>

          <table className="table mt-4 is-fullwidth">
            <tbody>
              <tr>
                <td>1</td>
                <td className="w-100">Reader for Terminal #{terminal.number}</td>
                <td>$0.00</td>
              </tr>
              <tr>
                <td>1</td>
                <td className="w-100">IDENTIFY {this.state.identificationString}</td>
                <td>$0.00</td>
              </tr>
              <tr>
                <td colSpan="2">Subtotal</td>
                <td>$0.01</td>
              </tr>
            </tbody>
          </table>
          <Button
            className=""
            text="Done"
            onClick={() => {
              const req = request.post(`/terminals/${terminal.id}/reader/clear`)
              this.setState({isIdentifyModalOpen: false, identificationString: ""})
            }}
          />
        </Modal>
        <div className="webapp-navbar-inner">
          <div className="left">
              <a href="/" className="brand">
                <img className="light-image" src="/images/stupid-logo.svg" alt="" />
              </a>
              <div className="separator"></div>
              <h1 id="webapp-page-title" className="title is-5" style={{whiteSpace: "nowrap"}}>
                Terminal #{terminal.number}
                <br/>
                <small className="subtitle is-size-6 text-no-wrap">{ terminal.store.name }</small>
              </h1>
          </div>
          <div className="center">
            <div className="centered-links">
              {
                // this.renderNavItem("Register", "/", "store")
              }
            </div>
          </div>
          <div className="right">
            <div className="navbar-item">
              <div className="buttons">
                { readerStatus == "online" &&
                  <Button
                    className="mr-2 is-link"
                    onClick={this.identifyReader}
                    loading={this.state.isIdentifying}
                    disabled={this.state.isIdentifying}
                    text="Identify Reader"
                  />
                }
                { readerStatus == "online" &&
                  <Button
                    className="mr-2"
                    onClick={() => request.post(`/terminals/${terminal.id}/reader/clear`)}
                    text="Clear Reader"
                  />
                }
                { readerStatus &&
                  <>
                    <Button
                      onClick={this.fetchReaderStatus}
                      text="Check Reader"
                      loading={checkingReader}
                    />
                    <Button
                      className={`button has-text-weight-bold mr-4 ${readerStatus == "online" ? "is-success" : "is-danger"}`}
                      text={`Reader ${startCase(this.state.readerStatus)}`}
                    />
                  </>
                }
                <Button
                  className="button is-outlined is-danger navbar-item ml-6"
                  onClick={this.props.handleLogout}
                  text="Logout"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}



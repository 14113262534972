import React from "react";
import request from "lib/request";

export default function hasData (WrappedComponent) {
  return class extends React.Component {
    constructor(props) {
      super(props)
      this.state = {
        formData: {}
      }
    }

    updateField = (field, value) => {
      this.setState(prevState => {
         let formData = Object.assign({}, prevState.formData);
         formData[field] = value;
         return { formData };
       })
    }

    clearForm = () => this.setState({formData: {}})

    seedFormData = ({formData}) => {
      let seedData = Object.assign({}, formData)
      this.setState({formData: seedData})
    }

    render() {
      return (
        <WrappedComponent
          {...this.props}
          formData={this.state.formData}
          seedFormData={this.seedFormData}
          updateField={this.updateField}
          clearForm={this.clearForm}
        />
      )
    }
  }
}

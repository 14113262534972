import axios from "axios"
import User from "User"
import Store from "Store"
import Terminal from "lib/auth/Terminal"
import Flash from "flash"

const authScope = {
  app: User,
  store: Store,
  internal: User,
  terminal: Terminal,
  t: Terminal,
}

let server = axios.create(
  {
    baseURL: '/api',
    responseType: 'json',
    headers: {
      "X-CSRF-Token": document.getElementsByName('csrf-token')[0]['content'],
      "X-CURRENT-PAGE": window.location.href,
    }
  }
)

// Add in auth token, always
server.interceptors.request.use(function (config) {
  const domain = window.location.hostname.split(".")[0]
  const auth = authScope[domain]
  if (auth) {
    config.headers.common["Authorization"] = auth.getAuthToken()
  }

  return config;
}, function (error) {
  // Do something with request error
  logger.error(error);
  return Promise.reject(error);
});

server.interceptors.request.use(function (config) {

  if (config.data && config.data.image) {
    config.headers["Content-Type"] = "multipart/form-data"
  }

  return config;
}, function (error) {
  // Do something with request error
  logger.error(error);
  return Promise.reject(error);
});


server.interceptors.response.use(function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  }, function (error) {
    if (error.response && error.response.status === 401) {
      const domain = window.location.hostname.split(".")[0]
      const auth = authScope[domain]
      if (auth.setLastPage) auth.setLastPage(window.location.pathname)
      auth.clearAuthToken()
      window.location.assign("/login?reauth=true")
    } else if (error.request && error.request.status == 422) {
      return Promise.reject(error);
    } else if (error.request && error.request.status != 500 && error.request.statuts != 422 && error.request.status != 404) {
      Flash.broadcast("error", "There was a problem with your internet connection. Please verify connectivity")
      return Promise.reject(error);
    } else {
      return Promise.reject(error);
    }
  });

let request = {
  get: server.get,
  post: server.post,
  put: server.put,
  patch: server.patch,
  delete: server.delete
}

export default request

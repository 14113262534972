import "./Switch.scss";

import React from "react";

export default class Switch extends React.PureComponent {
  handleOnChange = (e) => {
    e.preventDefault();
    if (this.props.disabled) return false
    this.props.onChange(!this.props.value)
  }

  render() {
    return(
      <div onClick={this.handleOnChange} className="Switch field block switch-block">
        <label className="form-switch is-success">
          <input
            type="checkbox"
            readOnly={true}
            checked={this.props.value}
            value={this.props.value}
            className={`is-switch ${this.props.classes}`}
            disabled={this.props.disabled}
          />
          <i/>
        </label>

        { this.props.label &&
          <div className="text">
            <span>{ this.props.label }</span>
          </div>
        }
      </div>
    )
  }
}

Switch.defaultProps = {
  disabled: false,
  classes: "",
}

import React from "react";
import ActionCable from 'actioncable';

const consumer = ActionCable.createConsumer(document.getElementsByName("action-cable-url")[0].content)

export default function hasWebsockets (WrappedComponent) {
  return class extends React.Component {
    subscribe = (channel, data, onReceived) => {
      consumer.subscriptions.create({channel: channel, ...data }, {
        received(data) { onReceived(data) }
      })
    }

    render() {
      return (
        <WrappedComponent
          {...this.props}
          subscribeTo={this.subscribe}
        />
      )
    }
  }
}


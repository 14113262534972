import "./SectionList.scss";

import React from "react";
import { Link } from "react-router-dom";
//import request from "request"
import usesFeatures from "usesFeatures"

import Header from "Header"
import Table from "Table"

// export default class SectionList extends React.PureComponent {
class SectionList extends React.PureComponent {
  state = {}

  componentDidMount() {
    this.props.fetchData("store/sections")
  }

  render() {
    if (!this.props.dataFetched) return false

    return(
      <div className="SectionList">
        <Header
          text="Orders By Section"
        />
        <div className="message is-warning p-3">
          This page only contains completed orders. For in progress orders go to the Orders area.
        </div>
        { Object.entries(this.props.tableData).map(([sectionId, data]) => {
          return(
            <div className="Section mb-4" key={sectionId}>
              <h3 className="title">
                { data.name }
              </h3>
              <Table
                showHeader={true}
                headers={DATA_HEADERS}
                formatters={DATA_FORMATTERS}
                rows={data.locations}
                empty="No Orders for this Section"
              />
            </div>
          )
        })}
      </div>
    )
  }
}

const DATA_HEADERS = {
  name: "Location",
  sales: "Sales",
  refunded: "Refunded",
  total: "Total",
}

const DATA_FORMATTERS = {
  //name: (d) => <Link to={`/sections/${d.sectionId}/${d.name}`}>{d.name}</Link>,
  sales: (d) => `$${d.sales}`,
  refunded: (d) => `$${d.refunded}`,
  total: (d) => `$${d.total}`,
}

export default usesFeatures(["data"], SectionList)

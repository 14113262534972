import "./ProductList.scss";

import React from "react";
//import { Link } from "react-router-dom";
import request from "request"
import usesFeatures from "usesFeatures"

import Header from "Header"
import Switch from "Switch"
import Table from "Table"

class ProductList extends React.PureComponent {
  constructor (props) {
    super(props)
    this.state = {}
  }

  componentDidMount() {
    this.props.fetchData("store/products")
  }

  productsFor = (category) => {
    return this.props.products.filter((p) => p.categoryId == category.simpleId)
  }

  emptyTable = () => {
    return <p>No products</p>
  }

  toggleProduct = (productId) => {
    let req = request.post(`store/products/${productId}/toggle`)
    req.then(() => {
      this.props.fetchData("store/products")
    })
  }

  productSwitch = (p) => {
    let disabled = p.active && !p.isActive

    return(
      <Switch
        onChange={this.toggleProduct.bind(null, p.id)}
        value={ disabled ? false : p.active}
        disabled={disabled}
      />
    )
  }

  tableFormatters = () => {
    return({
      toggle: (p) => this.productSwitch(p),
      price: (p) => `$${p.price}`,
    })
  }

  render() {
    if (!this.props.dataFetched) return false

    const { categories } = this.props
    const uncategorized = this.productsFor("")
    return(
      <div className="ProductList">
        <Header
          text="Store Products"
        />

        { categories.map((c) => {
          return(
            <div key={c.id} className="block">
              <h3 className="subtitle is-4">{ c.name }</h3>
              <Table
                empty={this.emptyTable(c)}
                showHeader={true}
                headers={PRODUCT_HEADERS}
                formatters={this.tableFormatters()}
                rows={this.productsFor(c)}
                tdClasses={TD_CLASSES}
              />
            </div>
          )
        })}
        { uncategorized.length > 0 &&
          <div key={"-1"} className="block">
            <h3 className="subtitle is-4">Uncategorized</h3>
            <Table
              empty={this.emptyTable("Uncategorized")}
              showHeader={true}
              headers={PRODUCT_HEADERS}
              formatters={this.tableFormatters()}
              rows={uncategorized}
              tdClasses={TD_CLASSES}
            />
          </div>
        }
      </div>
    )
  }
}

const PRODUCT_HEADERS = {
  toggle: "",
  name: "Product Name",
  price: "Price",
}

const TD_CLASSES = {
  toggle: "is-narrow",
  price: "is-narrow pr-4",
}

export default usesFeatures(["data"], ProductList)

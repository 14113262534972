import "./FlashMessages.scss";

import React from "react";

export default class FlashMessages extends React.Component {
  state = {
    messages: []
  }

  componentDidMount() {
    document.body.addEventListener('flashBroadcast', this.handleFlash.bind(this));
  }

  componentWillUnmount() {
    document.body.removeEventListener('flashBroadcast', this.handleFlash.bind(this));
  }

  handleFlash = (event) => {
    const { messages } = this.state
    const detail = event.detail
    detail.id = Math.random()
    detail.timeout = setTimeout(this.removeFlash.bind(null, detail.id), (this.props.timeout || 15000))
    messages.push(event.detail)
    this.setState({messages})
  }

  flashClass = (type) => {
    if(type == "error") return "is-danger"
    if(type == "warning") return "is-warning"
    if(type == "info") return "is-primary"
    if(type == "success") return "is-success"
    return "is-primary"
  }

  removeFlash = (id) => {
    const messages = this.state.messages.filter((m) => m.id != id)
    this.setState({messages})
  }

  render() {
    return(
      <div className="FlashMessages">
        { this.state.messages.map((message, i) => {
          return(
            <div className={`message ${this.flashClass(message.type)}`} key={i}>
              <a className="delete" onClick={this.removeFlash.bind(null, message.id)} ></a>
              <div className="message-body">
                { message.body }
              </div>
            </div>
          )
        })}
      </div>
    )
  }
}

import React from "react";
import { Elements, CardElement, ElementsConsumer } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(appConfig.spk);

export default function hasData (WrappedComponent) {
  return class extends React.PureComponent {
    render() {
      return(
        <Elements stripe={stripePromise}>
          <ElementsConsumer>
            {({elements, stripe}) => (
              <WrappedComponent
                {...this.props}
                elements={elements}
                stripe={stripe}
              />
            )}
          </ElementsConsumer>
        </Elements>
      )
    }
  }
}

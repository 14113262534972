import React from "react";
import { Redirect, Link } from "react-router-dom";
import "./VenueAdminLayout.scss";
import { ApplicationContext } from 'ApplicationContext'

import requests from "request";
import usesFeatures from 'usesFeatures'
import User from "User";
import VenueSelector from "VenueSelector";
import FlashMessages from "FlashMessages"
import TopNav from "./TopNav";
import Navigation from "./Navigation";
import Loader from "Loader"

class VenueAdminLayout extends React.PureComponent {
  static contextType = ApplicationContext;

  constructor(props) {
    super(props)
    this.state = {}
  }

  componentDidMount() {
    const { user, venue } = this.context.CurrentUserContext
    if(!user) this.context.CurrentUserContext.updateWhoAmI()
  }

  render() {
    const { user, venue } = this.context.CurrentUserContext

    return(
      <div className="VenueAdminLayout">
        <FlashMessages />
        <TopNav user={user} venue={venue} />
        <div className="view-wrapper">
          <Navigation user={user} venue={venue} />
          <div className="page-content-wrapper">
            <div className="page-content is-relative pt-4">
              { user && this.props.children }
              { !user && (<Loader />)}
            </div>
          </div>
        </div>
      </div>
    )
  }
}
export default usesFeatures(["data"], VenueAdminLayout)

import "./Form.scss";

import React from "react";
import { Link } from "react-router-dom";
import request from "request"
import usesFeatures from "usesFeatures"

export default class Form extends React.Component {
  handleSubmit = (e) => {
    e.preventDefault()
    this.props.onSubmit()
  }

  render() {
    return(
      <div className={`Form block ${this.props.wrapper ? "form-layout" : "nowrap"}`}>
        <div className="form-outer">
          { this.props.title &&
            <div className="form-header">
              <div className="left">
                <h3>{ this.props.title }</h3>
              </div>
            </div>
          }

          <div className="form-body">
            <form onSubmit={this.handleSubmit}>
              { this.props.children }
            </form>
          </div>
        </div>
      </div>
    )
  }
}

Form.defaultProps = {
  wrapper: true
}

import React from "react";
import request from "lib/request";
import User from "User"
import { ApplicationContext } from "ApplicationContext"
import Loader from "Loader"
import FourOhFour from "FourOhFour"

export default function hasData (WrappedComponent) {
  return class extends React.Component {
    static contextType = ApplicationContext
    constructor(props) {
      super(props)
      this.state = {
        hasDataFetch: false
      }
    }

    fetchData = (url, callback) => {
      if (url != "whoami") this.setState({hasDataFetch: true})
      let req = request.get(url)
      req.then(this.checkVersion).then(this.setData).then(callback)
      if (this.context) req.then(this.context.CurrentUserContext.fetched)
      req.catch(this.catchError)
    }

    catchError = ({ response }) => {
      if (response && response.status == 404) {
        this.setState({fourohfour: true})
      }
    }

    checkVersion = (data) => {
      if (appConfig.e == "development") return data

      let currentVersion = localStorage.getItem("catchApiVersion")
      localStorage.setItem("catchApiVersion", data.headers.api_version)
      if (currentVersion && currentVersion != data.headers.api_version) {
        window.location.reload(true)
      }
      return(data)
    }

    setData = ({data}) => {
      this.setState({dataFetched: true, ...data})
      return(data)
    }

    updateWith = (data) => {
      this.setState({...data})
    }

    render() {
      const { dataFetched } = this.state

      if (this.state.fourohfour) {
        return(
          <div className="FourOhFour">
            <FourOhFour />
          </div>
        )
      }

      return (
        <>
        { !this.state.dataFetched && this.state.hasDataFetch && <Loader />}
        <WrappedComponent
          {...this.state}
          {...this.props}
          fetchData={this.fetchData}
          updateWith={this.updateWith}
        />
        </>
      )
    }
  }
}

import React from "react";
import { Link } from "react-router-dom";

import Icon from "Icon"
import Confirm from "Confirm"

export default class Navigation extends React.Component {
  renderNavItem = (text, link, icon) => {
    let isActive = window.location.pathname.includes(link)
    if (window.location.pathname != "/" && link == "/") isActive = false
    return(
      <li className={`${isActive ? "is-active" : "" }`}>
        <Link to={link} className={`${isActive ? "is-active" : "" }`}>
          <Icon icon={icon} className="sidebar-svg" />
          { text }
        </Link>
      </li>
    )
  }

  render() {
    const { store } = this.props
    return(
      <div className="main-sidebar is-bordered">
          <div className="sidebar-brand is-bordered">
              <a href="/">
                <img className="light-image" src="/images/stupid-logo.svg" alt="" />
              </a>
          </div>
          <div className="sidebar-brand is-bordered">
            { store ? store.name : "" }
          </div>
          <div className="sidebar-inner">
              <ul className="icon-menu">
                { this.renderNavItem("Dashboard", "/", "home") }
                { this.renderNavItem("Orders", "/orders", "shopping-cart") }
                { store && store.allInclusive && store.delivery &&
                  this.renderNavItem("Orders by Section", "/sections", "chair")
                }
                { this.renderNavItem("Products", "/products", "box-open") }
                <li className="ml-4">
                  <Confirm
                    action={this.props.handleLogout}
                    message="Are you sure you want to log out? To log back in you'll need your store code again"
                  >
                  <span className="button is-outlined is-danger">Log Out</span>
                  </Confirm>
                </li>
              </ul>
          </div>
      </div>
    )
  }
}


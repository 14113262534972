import "./CustomerLayout.scss";

import React from "react";
import { Link } from "react-router-dom";
import request from "request"
import usesFeatures from "usesFeatures"

import LanguageSelector from "LanguageSelector"
import FlashMessages from "FlashMessages"

export default class CustomerLayout extends React.PureComponent {
  constructor (props) {
    super(props)
    this.state = {}
  }

  render() {
    return(
      <div className="CustomerLayout app-wrapper">
        <FlashMessages timeout={2500} />
        <nav className="navbar no-shadow">
          <div className="container">
            <div className="navbar-brand">
              <div className="brand-start">
                <img className="brand" src="/images/stupid-logo.svg" alt=""/>
              </div>
              <div className="brand-end" />
            </div>
            <div className="navbar-menu">
              <div className="navbar-end">
                <LanguageSelector />
              </div>
            </div>
          </div>
        </nav>
        <div className="page-content-wrapper pt-4">
          <div className="page-content">
            { this.props.children }
          </div>
        </div>
      </div>
    )
  }
}

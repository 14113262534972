import "./OrderCompleteForm.scss";

import React from "react";
import { Link, Redirect } from "react-router-dom";
import request from "request"
import usesFeatures from "usesFeatures"
import { times } from "lodash";

import Form from "Form"
import Button from "Button"
import Header from "Header"
import Confirm from "Confirm"
import Checkbox from "Checkbox"

const CANCEL_MESSAGE = "The customer was unable to pick up any items. The customer will be completely refunded, please confirm"

class OrderCompleteForm extends React.PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      verified: false
    }
  }

  componentDidMount() {
    const orderId = this.props.computedMatch.params.orderId
    this.props.fetchData(`store/orders/${orderId}`, this.seedPickup)
  }

  seedPickup = (data) => {
    let productState = {}
    data.cart.products.forEach((p) => {
      times(p.quantity, (n) => {
        productState[`${p.id}-${n}`] = false
      })
    })
    this.setState({products: productState})
  }

  canSubmit = () => {
    const { order } = this.props

    if (!order.verify) return true
    return order.verify && this.state.verified
  }

  canSubmitPartial = () => this.canSubmit() && Object.values(this.state.products).filter(Boolean).length

  productFulfilled = (product, i) => {
    const { products } = this.state
    let key = `${product.id}-${i}`
    products[key] = !products[key]
    this.setState(products)
  }

  handleEmpty = () => {
    this.setState({submittingEmpty: true})
    let req = request.post(`store/orders/${this.props.order.id}/cancel`)
    req.then(({data}) => {
      if (data.success) {
        this.setState({completed: true})
      } else {
        this.setState({submittingEmpty: false})
      }
    })
  }

  handleComplete = (partial) => {
    if (this.state.submitting) return false

    this.setState({submitting: true})
    let params = {
      partial: partial,
      verified: this.state.verified,
      pickedUp: this.state.products,
    }

    let req = request.post(`store/orders/${this.props.order.id}/complete`, params)
    req.then(({data}) => {
      if (data.success) this.setState({completed: true, submitting: false})
    })
  }

  render() {
    if (!this.props.dataFetched) return false
    if (!this.state.products) return false

    const { order, customer, cart } = this.props

    if (this.state.completed) return <Redirect to={`/orders/${order.id}`} />


    return(
      <div className="OrderCompleteForm">
        <Header
          text={`Order #${order.number} for ${customer.name} `}
          backLink={`/orders/${order.id}`}
          actions={true}
        >
        </Header>

        <p className="is-size-4 block">
          Confirmation/Pickup Code:
          <span className="has-text-weight-bold ml-2">{ [ order.confirmationCode.slice(0, 3), order.confirmationCode.slice(3) ].join('-') }</span>
        </p>
        <p className="is-size-4 block">
          Customer Name:
          <span className="has-text-weight-bold ml-2">{ customer.name }</span>
        </p>
        <Form onSubmit={this.handleComplete.bind(null, false)}>
          { order.verify &&
            <div className="block">
              <p className="title is-size-4">Verification Requried</p>
              <Checkbox
                onChange={(val) => this.setState({verified: val})}
                value={this.state.verified}
                label="Customer is of legal age for products received"
              />
            </div>
          }
          <button
            disabled={!this.canSubmit()}
            className={`button is-primary ${this.state.submitting ? "is-loading" : ""}`}
          >
            Complete Order
          </button>
        </Form>

        <Form onSubmit={this.handleComplete.bind(null, true)}>
          <p className="title is-size-4">Partial Pickup - Items Missing or Unavailable</p>
          <p className="is-size-5 block">The customer picked up...</p>
          <table className="table is-fullwidth">
            <thead>
              <tr>
                <th className="is-narrow">Picked Up?</th>
                <th className="has-text-left">Product</th>
              </tr>
            </thead>
            <tbody>
              { cart.products.map((product, pi) => {
                { return times(product.quantity, (n) => {
                  return(
                    <tr key={`${pi}-${n}`}>
                      <td className="has-text-centered">
                        <Checkbox
                          onChange={this.productFulfilled.bind(null, product, n)}
                          value={this.state.products[`${product.id}-${n}`] ? true : false}
                        />
                      </td>
                      <td>
                        { product.name }
                        { product.modifiers.length > 0 && <p>
                          <span className="mr-2 has-text-weight-bold">Extras:</span>
                          { product.modifiers.map((m) => m.name).join(", ") }
                        </p> }
                      </td>
                    </tr>
                  )
                })}
              })}
            </tbody>
          </table>
          <button
            disabled={!this.canSubmitPartial()}
            className={`button is-primary ${this.state.submitting ? "is-loading" : ""}`}
          >
            Complete Order
          </button>

          <Confirm
            action={this.handleEmpty}
            message={CANCEL_MESSAGE}
            className="CancelModal"
            loading={this.state.submittingEmpty}
          >
            <Button
              color="red"
              text="No Items Available"
              loading={this.state.submittingEmpty}
            />
          </Confirm>
        </Form>
      </div>
    )
  }
}

export default usesFeatures(["data"], OrderCompleteForm)

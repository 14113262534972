import React from "react";
import "./FourOhFour.scss";

import { sample } from "lodash"
import { withRouter } from 'react-router-dom';

const IMAGES = [
  "1.gif",
  "2.gif",
  "3.gif",
  "4.gif",
  "5.gif",
]

class FourOhFour extends React.Component {
  getRandomImage = () => {
    return(sample(IMAGES))
  }
  render() {
    console.log(this.props)
    return(
      <div className="FourOhFour" style={{backgroundImage: `url(/images/404s/${this.getRandomImage()}`}}>
        <div className="Error">
          <h3 className="is-size-6">Page Not Found - Error 404</h3>
          <p>Sorry, we seem to be missing what you're looking for</p>
          <p>
            Try going <a onClick={this.props.history.goBack}>back</a>
          </p>
          <p>If this error persists, please let us know at <a href="mailto:support@gocatch.io">support@gocatch.io</a></p>
        </div>
      </div>
    )
  }
}
export default withRouter(FourOhFour)

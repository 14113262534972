import React, { Component } from 'react';
import PropTypes            from 'prop-types';

import request from "request"
import User from "User"

export const contextName = 'CurrentUserContext';
export const CurrentUserContext = React.createContext();
export default class CurrentUserProvider extends Component {
  state = {
    currentUser: null,
    currentVenue: null,
  }

  componentDidMount() {
    if(!this.state.currentUser) this.updateWhoAmI()
  }

  updateWhoAmI = () => {
    let req = request.get("whoami")
    req.then(({data}) => {
      this.setState({currentUser: data.user, currentVenue: data.venue})
    })
  }

  fetched = ({data}) => {
    if (data.userUpdated != this.state.currentUser.updatedAt) this.updateWhoAmI()
  }

  canUse = (permission) => {
    return this.state.currentUser.permissions.includes(permission)
  }

  render() {
    const { currentUser, currentVenue } = this.state

    return (
      <CurrentUserContext.Provider
        value={{
          user: currentUser,
          venue: currentVenue,
          canUse: this.canUse,
          fetched: this.fetched,
          updateWhoAmI: this.updateWhoAmI,
        }}
      >
        {this.props.children}
      </CurrentUserContext.Provider>
    )
  }
}

import React from 'react'

import NoLayout from "NoLayout"
import AuthLayout from "AuthLayout"
import StoreLayout from "StoreLayout"
import InternalLayout from "InternalLayout"
import CustomerLayout from "CustomerLayout"
import VenueAdminLayout from "VenueAdminLayout"
import TerminalLayout from "TerminalLayout"

const LAYOUTS = {
  None: NoLayout,
  Auth: AuthLayout,
  Store: StoreLayout,
  Internal: InternalLayout,
  Customer: CustomerLayout,
  VenueAdmin: VenueAdminLayout,
  Terminal: TerminalLayout,
}

export default function wrapComponent(ComponentToWrap, layout, variant) {
  return class WrappedComponent extends React.PureComponent {
    render() {
      return (
        <ApplicationWrapper layout={layout} className={variant} >
          <ComponentToWrap {...this.props} />
        </ApplicationWrapper>
      )
    }
  }
}

export class ApplicationWrapper extends React.PureComponent {
  layoutLookup = (identifier) => {
    if (identifier) {
      return LAYOUTS[identifier]
    } else {
      return LAYOUTS.None
    }
  }

  render() {
    const { children } = this.props;
    const Layout = this.layoutLookup(this.props.layout)

    return (
      <Layout { ...this.props } className={this.props.className}>
        { children }
      </Layout>
    )
  }
}

import "./LanguageSelector.scss";

import React from "react";
//import { Link } from "react-router-dom";
//import request from "request"
import i18n from "lib/i18n"
import usesFeatures from "usesFeatures"

import { FeatureFlagContext } from "FeatureFlagContext"

const LANGS = {
  "en-US": "English",
  fr: "French"
}

export default class LanguageSelector extends React.PureComponent {
  static contextType = FeatureFlagContext
  state = {
    open: false
  }

  changeLanguage = (lang) => {
    i18n.changeLanguage(lang)
    this.setState({ open: false })
  }

  toggleList = () => {
    this.setState({ open: !this.state.open })
  }

  renderLanguageSelection = (lang) => {
    if (localStorage.getItem("i18nextLng") == lang) return null
    return(
      <a onClick={this.changeLanguage.bind(null, lang)} key={lang} className="navbar-item">{ LANGS[lang] }</a>
    )
  }

  render() {
    if (!this.context.isEnabled("language_selection")) return null
    return(
      <div className={`LanguageSelector navbar-item has-dropdown ${this.state.open ? "is-active" : ""}`}>
        <a className="navbar-link" onClick={this.toggleList}>{ LANGS[localStorage.getItem("i18nextLng") || "en"] }</a>
        { this.state.open &&
          <div className="navbar-dropdown is-right">
            { this.renderLanguageSelection("en-US") }
            { this.renderLanguageSelection("fr") }
            <a className="navbar-item" onClick={this.toggleList}>Close</a>
          </div>
        }
      </div>
    )
  }
}
